<template>
  <div class="twitter-widget">
    <a class="twitter-timeline" href="https://twitter.com/chimni?ref_src=twsrc%5Etfw">Tweets by Chimni</a>
  </div>
</template>

<script>
export default {
  name: 'TwitterWidget',
  mounted () {
    const twitterWidgets = document.createElement('script')
    twitterWidgets.setAttribute('src', 'https://platform.twitter.com/widgets.js')
    twitterWidgets.setAttribute('charset', 'utf-8')
    twitterWidgets.setAttribute('async', '')
    document.head.appendChild(twitterWidgets)
  }
}
</script>

<style lang="scss" scoped>
  @import '../../../../styles/variables';
  .twitter-widget {
    width: 70%;
    margin: 0 auto;
    height: 800px;
    overflow: auto;
    max-width: 400px;
    @media only screen and (min-width: $md-screen) {
      width: 100%;
    }
  }
</style>
